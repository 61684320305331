:root {
    --primary-color: #c8e455;
    --background-color: #F7FFDA;
    --text-color: #000;
    --border-radius: 25px;

}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    
}

body {
    background-color: var(--background-color);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.avatar {
    position: absolute;
    width: 143px;
    height: 137px;
    left: calc(50% - 143px / 2 - 10.5px);
    top: 47px;
  }
.displayname{
    /* Button */

/* Auto layout */
display: flex;
flex-direction: column;
align-items: center;
padding: 0px;
gap: 9.13px;

position: absolute;
width: 141px;
height: 27px;
left: calc(50% - 141px/2 - 0.5px);
top: 210px;
font-size: 16px;
border-radius: 18.2696px;
font-family: 'Nunito', sans-serif;

}
.secretmessage{
    font-size: 22px; 
    font-family: 'Nunito', sans-serif;
    font-weight: 800; 
}
.container {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Adjust if needed */
    align-items: center; /* Align items horizontally in the center */
    width: 100%; /* Ensure the container spans the full width */
    height: 100vh; /* Make the container take the full viewport height */
    position: relative; /* Position the child elements relative to this */
    z-index: auto;

}

.message-card {
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 200px;

}

.avatar {
    width: 80px;
    height: 80px;
    margin: 0 auto;
}

h2 {
    font-size: 1.2rem;
    color: var(--text-color);
}

h1 {
    font-size: 1.8rem;
    color: var(--text-color);
    margin-bottom: 20px;
}

.message-input {
    position: relative;
    margin: 20px 0;
}
.setmessage{
    /* Button */

box-sizing: border-box;

/* Auto layout */
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 0px;
gap: 9.13px;

position: absolute;
width: 291px;
height: 204px;
left: 54px;
top: 317px;

background: #F5FFCF;
border: 1px solid #000000;
box-shadow: 0px 4px 0px #000000;
border-radius: 18.2696px;

}

textarea {
    width: 100%;
    height: 150px;
    padding: 15px;
    border: 1px solid #000;
    border-radius: 15px;
    background-color: var(--background-color);
    font-size: 1rem;
    resize: none;
    /* Button */

    box-shadow: 0px 4px 0px #000000;
    border-radius: 18.2696px;

}

.dice-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.send-button {
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: 25px;
    padding: 15px 0;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    margin: 20px 0;


background: #daff44;
box-shadow: 0px 3.50308px 0px #000000;
border-radius: 18.2696px;

}

.logo {
    margin: 30px 0;
}

.loons-logo {
    width: 150px;
    height: auto;
}

.footer {
    margin-top: 30px;
    text-align: center;
}

.footer h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.footer p {
    color: var(--text-color);
    margin-bottom: 20px;
}

.links {
    display: flex;
    justify-content: center;
    gap: 20px;
    /* Group 1000004304 */
}

.links a {
    color: var(--text-color);
    text-decoration: none;
    font-size: 0.9rem;
}

textarea::placeholder {
    color: #666;
}

textarea:focus {
    outline: none;
    border-color: black;
}
.right-grass-icon {
    position: fixed; /* Anchors to the viewport */
    bottom: 0; /* Align to the bottom */
    right: 0; /* Align to the right */
    z-index: 1000; /* High value to appear above other elements */
    width: 100px; /* Adjust size as needed */
    height: auto;
}

.footer_message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 9.13px;

    width: 403px;
    height: 52px;
    

    background: #000000;
    box-shadow: 0px 3.50308px 0px #000000;

    position: relative; /* Change to relative for flexbox centering */
    margin: 0 auto; /* Center horizontally */


    font-family: 'Nunito';
font-style: normal;
font-weight: 800;
font-size: 24px;
line-height: 23px;
color: #daed01
}

p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 9.13px;

    width: 100%;
    height: 27px;
    font-family: 'Nunito', sans-serif; /* Ensure proper font-family syntax */
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 23px;

    background: #daed01;

    position: relative; /* Change to relative for flexbox centering */
    margin: 0 auto; /* Center horizontally */

}


